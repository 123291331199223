import { add, Controller } from "stimulus"
import mapboxgl from "mapbox-gl"

export default class extends Controller {
  static targets = [ "output"]
  static values = { apiKey: String, address: String, lockers: Array}

  connect() {
    console.log("hello from controller")
    console.log(JSON.parse(this.addressValue))
    console.log(this.lockersValue)
    this.map = this.#buildMap()
    this.map.resize()
    this.#addMarkers()
  }

  #buildMap = () => {
    mapboxgl.accessToken = this.apiKeyValue;
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v9',
      center: JSON.parse(this.addressValue),
      pitch: 45,
      zoom: 12
    });
    return map
  }

  #addMarkers = () => {
    const bounds = new mapboxgl.LngLatBounds()
    this.lockersValue.forEach(locker => {
      const marker = new mapboxgl.Marker()
      marker.setLngLat(locker)
      marker.addTo(this.map)
      bounds.extend([ marker._lngLat.lng, marker._lngLat.lat ])
    })
    const marker = new mapboxgl.Marker({ color: 'black' })
    marker.setLngLat(JSON.parse(this.addressValue))
    marker.addTo(this.map)
    bounds.extend([ marker._lngLat.lng, marker._lngLat.lat ])
    this.map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
  }
}